<template>
<div class="overlay">
  <div>
    <img src="/assets/images/edit-tool.png" alt="">
    <span>This page is still being built. <br /> We appreciate your patience</span>
  </div>
</div>
  <div class="contentTitle">
    <h3>Performance</h3>
    <div class="btn-cluster">
                      <a href="#" class="btn">Day</a><a href="#" class="btn selected">Week</a><a href="#" class="btn">Month</a><a href="#" class="btn">Year</a>
                  </div>
  </div>

  <div class="contentMenu">
    <ul class="navbar-nav">
      <li class="nav-item active">
        <a class="nav-link" href="#">Performance</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">Client</a>
      </li>
    </ul>

  </div>

  <div class="dashboard-wrapper">
    <div class="column1">
        <div class="dashboard-inner-block">
            <div class="ticket-left-top">
                <a href="" class="more">...</a>
                Issues
                <div class="ticket-left-date">
                    February 8, 2021
                    <a href=""><i class="fa fa-angle-left" aria-hidden="true"></i></a><a href=""><i class="fa fa-angle-right" aria-hidden="true"></i></a>
                </div>
            </div>
            <div class="graph">
                <img src="/assets/images/graph1.jpg" alt="">
            </div>
        </div>
    </div>
    <div class="column2">
        <div class="dashboard-inner-block">
            <div class="ticket-left-top">
                <a href="" class="more">...</a>
                Tickets
                <div class="ticket-left-date">
                    February 8, 2021
                    <a href=""><i class="fa fa-angle-left" aria-hidden="true"></i></a><a href=""><i class="fa fa-angle-right" aria-hidden="true"></i></a>
                </div>
            </div>
            <div class="graph">
                <img src="/assets/images/graph2.jpg" alt="">
            </div>
        </div>
        <div class="dashboard-inner-block">
            <div class="ticket-left-top">
                <a href="" class="more">...</a>
                Issues Progress
                <div class="ticket-left-date">
                    February 8, 2021
                    <a href=""><i class="fa fa-angle-left" aria-hidden="true"></i></a><a href=""><i class="fa fa-angle-right" aria-hidden="true"></i></a>
                </div>
            </div>
            <div class="graph">
                <img src="/assets/images/graph3.jpg" alt="">
            </div>
        </div>
    </div>
    <div class="column1">
        <div class="dashboard-inner-block">
            <div class="ticket-left-top">
                <a href="" class="more">...</a>
                Issues Priority
                <div class="ticket-left-date">
                    February 8, 2021
                    <a href=""><i class="fa fa-angle-left" aria-hidden="true"></i></a><a href=""><i class="fa fa-angle-right" aria-hidden="true"></i></a>
                </div>
            </div>
            <div class="graph">
                <img src="/assets/images/graph4.jpg" alt="">
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
  title () {
    return `Remote Assistance | ${this.title}`
  },
  data(){
    return{
      title: 'Performance',
    }
  },
  mounted(){
    this.emitter.emit("activate-main-menu", 'performance');
  },
}
</script>

<style lang="scss" scoped>
.overlay {
    position: absolute;
    width: 100%;
    height: calc(100% - 100px);
    backdrop-filter: blur(5px);
    z-index: 5;
    top: 100px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: -100px;
      gap: 20px;
      img {
        width: 50px;
        height: 50px;
      }
      span {
        text-align: center;
        font-size: 1.5rem;
        font-weight: bold;
      }
    }
}
</style>